// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-departments-anaesthesia-pain-medicine-tsx": () => import("./../../../src/pages/departments/anaesthesia-pain-medicine.tsx" /* webpackChunkName: "component---src-pages-departments-anaesthesia-pain-medicine-tsx" */),
  "component---src-pages-departments-general-laproscopic-surgery-tsx": () => import("./../../../src/pages/departments/general-laproscopic-surgery.tsx" /* webpackChunkName: "component---src-pages-departments-general-laproscopic-surgery-tsx" */),
  "component---src-pages-departments-index-tsx": () => import("./../../../src/pages/departments/index.tsx" /* webpackChunkName: "component---src-pages-departments-index-tsx" */),
  "component---src-pages-departments-oral-maxillofacial-surgery-tsx": () => import("./../../../src/pages/departments/oral-maxillofacial-surgery.tsx" /* webpackChunkName: "component---src-pages-departments-oral-maxillofacial-surgery-tsx" */),
  "component---src-pages-departments-orthopedic-joint-replacement-tsx": () => import("./../../../src/pages/departments/orthopedic-joint-replacement.tsx" /* webpackChunkName: "component---src-pages-departments-orthopedic-joint-replacement-tsx" */),
  "component---src-pages-departments-plastic-reconstructive-surgery-tsx": () => import("./../../../src/pages/departments/plastic-reconstructive-surgery.tsx" /* webpackChunkName: "component---src-pages-departments-plastic-reconstructive-surgery-tsx" */),
  "component---src-pages-departments-surgical-gynecology-tsx": () => import("./../../../src/pages/departments/surgical-gynecology.tsx" /* webpackChunkName: "component---src-pages-departments-surgical-gynecology-tsx" */),
  "component---src-pages-departments-surgical-oncology-tsx": () => import("./../../../src/pages/departments/surgical-oncology.tsx" /* webpackChunkName: "component---src-pages-departments-surgical-oncology-tsx" */),
  "component---src-pages-departments-surgical-urology-tsx": () => import("./../../../src/pages/departments/surgical-urology.tsx" /* webpackChunkName: "component---src-pages-departments-surgical-urology-tsx" */),
  "component---src-pages-departments-vascular-surgery-tsx": () => import("./../../../src/pages/departments/vascular-surgery.tsx" /* webpackChunkName: "component---src-pages-departments-vascular-surgery-tsx" */),
  "component---src-pages-facility-tsx": () => import("./../../../src/pages/facility.tsx" /* webpackChunkName: "component---src-pages-facility-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-vision-tsx": () => import("./../../../src/pages/vision.tsx" /* webpackChunkName: "component---src-pages-vision-tsx" */)
}

