import { Theme } from "theme-ui";

const theme: Theme = {
  // useColorSchemeMediaQuery: true,
  colors: {
    primary: `#1CADC8`,
    grays: [
      `#f8f9fa`,
      `#f1f3f5`,
      `#e9ecef`,
      `#dee2e6`,
      `#ced4da`,
      `#adb5bd`,
      `#868e96`,
      `#495057`,
      `#343a40`,
      `#212529`,
    ],
    orange: [
      `#fff4e6`,
      `#ffe8cc`,
      `#ffd8a8`,
      `#ffc078`,
      `#ffa94d`,
      `#ff922b`,
      `#fd7e14`,
      `#f76707`,
      `#e8590c`,
      `#d9480f`,
    ],
    secondary: '#174068',
    dim: '#174068d1',
    bgs: '#fff',
    base: `#fff`,    
  },
  fontSizes: [16, 19.2, 23.04, 27.6, 33.1, 39.8, 47.7, 57.3, 68],
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256],
  layout: {
    container: {
      maxWidth: [`100%`, `70ch`, `140ch`],
      px: [4, 0, 0],
    },
  },
  fonts: {
    body: ` graphie, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`,
    heading: `graphie, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`,
    monospace: `Menlo, monospace`,
  },
  fontWeights: {
    thin: 200,
    body: 400,
    heading: 500,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.1275,
  },
  text: {
    p: {
      bg: `red`,
    },
    hero: {
      fontSize: [5, 4, 8],
      lineHeight: `heading`,
      textAlign: [`center`, `left`, `left`],
      mb: 3,
      color: `primary`,
    },
    hero2: {
      variant: `text.hero`,
      fontSize: [5, 4, 4],
      fontWeight: `body`,
      color: `grays.7`,
    },
    dev: {
      // bg: 'orange.1',
    },
    pageTitle: {
      fontSize: 5,
      mb: 4,
      color: 'primary',
      lineHeight: `heading`,
      fontFamily: 'heading',
    },
    sectionTitle: {
      variant: `text.dev`,
      color: `grays.7`,
      fontSize: 3,
      lineHeight: `heading`,
      fontFamily: 'heading',
      mb: 2,
    },
    innerTitle: {
      variant: `text.dev`,
      color: `grays.7`,
      fontSize: 3,
      lineHeight: `heading`,
      fontFamily: 'heading',
      mb: 2,
    },
    sectionBody: {
      variant: `text.dev`,
      color: `grays.6`,
      fontSize: 2,
      fontWeight: `body`,
      lineHeight: `body`,
    },
    sectionBodyAlt: {
      variant: `text.dev`,
      color: `grays.6`,
      fontSize: 3,
      fontWeight: `body`,
      lineHeight: `body`,
    },
    blockTitle: {
      fontSize: 1,
      // bg: 'orange.1',
      lineHeight: `heading`,
      // fontFamily: 'heading',
      fontWeight: 900,
    },
  },
  buttons: {},
  forms: {
    input: {
      default: {
        borderRadius: 0,
      },
    },
  },
  links: {
    navList: {
      color: `grays.0`,
      fontSize: [2, 2, 2],
      textAlign: [`center`, `left`, `left`],
      fontWeight: 600,
      mt: [5,0,0],
    },
    button: {
      // bg: `red`,
      fontSize: 1,
      fontWeight: "bold",
      borderRadius: 2,
      px: 4,
      py: 2,
      mr: 2,
      bg: "grays.9",
      color: "grays.0",
    },
    nav: {
      color: `grays.7`,
      // bg: 'red'
    },
  },
  styles: {
    // nav
    navLink: {
      listStyle: `none`,
      alignItems: `center`,
      fontWeight: 'bold',
      mr: 3,
      mb: 2,
    },
    navLinkBig: {
      bg: 'red',
      a: {
        bg: 'white'
      }
    },
    dev: {
      border: `solid 1px`,
      borderColor: `orange.2`,
      bg: `orange.0`,
    },
    flexBox: {
      flexDirection: [`column`, `column`, `row`],
    },
    header: {
      // variant: 'styles.dev',
      py: 4,
    },
    cagedTitle: {
      maxWidth: [`auto`, `auto`, `13ch`],
    },
    bodyFrame: {
      px: 0,
      // bg: 'red',
      pb: 6,
      maxWidth: [`auto`, `auto`, `110ch`],
    },
    contentFrame: {
      bg: 'bgs',
      pt: 7,
      pb: 5,
    },
    contentFrame2: {
      // bg: 'red',
      pt: 6,
      // pr: 5,
      maxWidth: '70ch',
      pb: 5,
    },
    contentFrameNil: {
      // bg: 'red',
      pt: 0,
      // pr: 5,
      // maxWidth: '70ch',
      pb: 5,
    },
    navFrame: {
      left: 0,
      right: 0,
      zIndex: 1000,
      bg: `base`,
      top: [0, `auto`, `auto`],
      bottom: [`auto`, `auto`, `auto`],
      borderBottom: `solid 1px`,
      borderColor: `grays.3`,
      flexBasis: `sidebar`,
      minWidth: [`100vw`, `auto`, `auto`, `auto`],
    },
    navGroup: {
      ml: `auto`,
      justifyContent: `center`,
      alignItems: `center`,
      // bg: 'green',
      display: [`flex`, `inherit`, `inherit`],
    },
    plateBase: {
      variant: `styles.header`,
    },
    plateHeader: {
      variant: `styles.header`,
      p: 0,
      m: 0,
    },
    plateSection: {
      variant: `styles.header`,
      py: 5,
    },
    platePage: {
      variant: `styles.header`,
      py: 7,
    },
    plateHero: {
      variant: `styles.header`,
      minHeight: `50vh`,
      borderBottom: `solid 1px`,
      borderColor: `grays.1`,
      mb: 6,
      bg: "grays.4",
    },
  },
  images: {
    featureIcon: {
      width: [`100%`, `60px`, `80px`],
      height: `auto`,
      ml: [0, 0, `auto`],
      mt: 2,
    },
  },
};

export default theme;
